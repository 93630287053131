<template>
  <div class="flex flex-row items-center py-1 pl-2">
    <div class="flex-1">
      <p class="text-gray-100 text-sm font-semibold">{{user.name}} {{user.login}}</p>
    </div>
    <div class="flex-1">
      <span
        v-if="user.is_admin"
        class="bg-blue-400 text-gray-100 text-xs font-semibold py-1 rounded-sm leading-5 px-2 mx-1 shadow-sm"
      >Admin</span>
      <span
        v-if="user.is_tech"
        class="bg-red-400 text-gray-50 text-xs font-semibold py-1 rounded-sm leading-5 px-2 mx-1 shadow-sm"
      >Tech</span>
      <span
        v-if="user.is_sale"
        class="bg-green-400 text-xs font-semibold py-1 rounded-sm leading-5 px-2 mx-1 shadow-sm"
      >Sale</span>
    </div>
    <div class="flex">
      <button
        class="text-yellow-300 px-2 rounded-md hover:bg-gray-200 font-medium"
        v-on:click.prevent="INIT_EDIT_USER(user)"
      >Edit</button>
      <button
        class="text-red-400 px-2 rounded-md hover:bg-gray-200 font-medium"
        v-on:click.prevent="DELETE_USER(user.id)"
      >Delete</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {};
  },
  methods: {
    INIT_EDIT_USER: function (user) {
      this.$emit("initedituser", user);
    },
    DELETE_USER: function (id) {
      this.$store.dispatch("POST_DELETE_USER", { id }).then(
        (response) => {
          this.$emit("updateusers", response.data);
        },
        (error) => {
          if (error.response.status >= 400) {
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            alert("Something went wrong...");
            window.location.reload();
          }
        }
      );
    },
  },
};
</script>
