<template>
  <div class="h-full w-full">
    <div
      v-if="hasError"
      class="min-h-full min-w-full flex bg-gray-500"
    >
      <div class="flex mx-auto my-auto text-red-400 flex-row">
        <p class="font-normal text-2xl">
          <span class="font-extrabold text-6xl">ERROR</span>
          Please log out and back in
        </p>
      </div>
    </div>

    <div
      v-else
      class="h-full flex flex-col px-0 pt-2 pr-2 pb-4"
    >
      <div class="bg-blue-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex">
        <div class="hidden xl:flex my-auto">
          <span class="mx-1">USER_MANAGER</span>
        </div>
        <div class="flex flex-col pt-2 md:pt-0 md:flex-row my-auto">
          <div class="flex flex-col md:flex-row px-2 my-auto">
            <input
              type="text"
              class="rounded-sm border border-blue-100 leading-tight h-6 mx-1 my-1 px-2 placeholder-gray-600 form-input block text-xs text-black"
              placeholder="name"
              v-model="NEW_USER.name"
            />
            <input
              type="text"
              class="rounded-sm border border-blue-100 leading-tight h-6 mx-1 my-1 px-2 placeholder-gray-600 form-input block text-xs text-black"
              placeholder="email"
              v-model="NEW_USER.login"
            />
            <input
              type="password"
              class="rounded-sm border border-blue-100 leading-tight h-6 mx-1 my-1 px-2 placeholder-gray-600 form-input block text-xs text-black"
              placeholder="password"
              v-model="NEW_USER.password"
            />
          </div>

          <div class="flex flex-row my-auto">
            <div class="relative flex items-center my-1 leading-tight">
              <div class="flex items-center">
                <input
                  id="ais_admin"
                  type="checkbox"
                  class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out ml-2"
                  v-model="NEW_USER.is_admin"
                />
              </div>
              <div class="ml-1 text-sm leading-5">
                <label
                  for="ais_admin"
                  class="font-normal text-gray-700"
                >admin</label>
              </div>
            </div>
            <div class="relative flex items-center my-1 leading-tight">
              <div class="flex items-center">
                <input
                  id="ais_tech"
                  type="checkbox"
                  class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out ml-2"
                  v-model="NEW_USER.is_tech"
                />
              </div>
              <div class="ml-1 text-sm leading-5">
                <label
                  for="ais_tech"
                  class="font-normal text-gray-700"
                >tech</label>
              </div>
            </div>
            <div class="relative flex items-center my-1 leading-tight">
              <div class="flex items-center">
                <input
                  id="ais_sale"
                  type="checkbox"
                  class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out ml-2"
                  v-model="NEW_USER.is_sale"
                />
              </div>
              <div class="ml-1 text-sm leading-5">
                <label
                  for="ais_sale"
                  class="font-normal text-gray-700"
                >sale</label>
              </div>
            </div>

            <!-- <div class="flex"> -->
            <button
              v-bind:class="
                NEW_USER.login != '' &&
                NEW_USER.email != '' &&
                NEW_USER.password != ''
                  ? 'disabled'
                  : ''
              "
              v-on:click.prevent="ADD_USER"
              class="flex px-2 ml-2 border border-gray-50 my-2 rounded-sm"
            >
              Add
            </button>
          </div>
        </div>

        <div class="hidden xl:flex">
          <button
            class="flex font-normal px-2 mx-1 border border-gray-50 my-2 rounded-sm"
            v-on:click.prevent="switchAutoUpdate()"
          >
            {{
              !enableAutoUpdate ? "ENABLE:AUTO_UPDATE" : "DISABLE:AUTO_UPDATE"
            }}
          </button>
        </div>
      </div>
      <div class="flex-col px-0 md:px-1 py-2 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none max-h-full">
        <div class="flex">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-1 gap-y-1 w-full">
            <User
              v-for="user in USERS"
              v-bind:key="user.id"
              v-bind:user="user"
              @updateusers="UPDATE_USERS"
              @initedituser="INIT_EDIT_USER"
              class="rounded-sm shadow-md font-normal text-gray-50 flex-row leading-5 bg-gray-600"
            ></User>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="show_edit_user"
      class="fixed inset-0 overflow-y-hidden max-h-1/4"
    >
      <div class="absolute inset-0 overflow-hidden">
        <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <div class="w-screen max-w-4xl">
            <div class="h-full flex flex-col space-y-1 py-4 bg-gray-100 shadow-xl">
              <header class="px-4">
                <div class="flex items-start justify-between space-x-3">
                  <h2 class="text-lg leading-7 font-normal text-gray-900">
                    Edit... or edit not. There is no try.
                  </h2>
                  <div class="h-7 flex items-center">
                    <button
                      v-on:click.prevent="show_edit_user = !show_edit_user"
                      aria-label="Close panel"
                      class="text-gray-500 hover:text-gray-600 transition ease-in-out duration-150"
                    >
                      <svg
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="4"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </header>
              <div class="relative flex-1 px-2 py-2 max-h-full overflow-y-scroll">
                <div class="inset-0 px-2 h-full">
                  <div class="h-full flex-row mx-2 px-2 pt-5 bg-gray-700 rounded-sm">
                    <div class="flex flex-row h-full shadow-sm rounded-sm py-1">
                      <div class="flex flex-col h-full">
                        <div class="flex flex-row">
                          <input
                            type="text"
                            class="rounded-sm border border-blue-100 leading-tight mx-1 my-1 px-2 py-1 placeholder-gray-600 form-input block"
                            placeholder="name"
                            v-model="EDIT_USER.name"
                          />
                          <input
                            type="text"
                            class="rounded-sm border border-blue-100 leading-tight mx-1 my-1 px-2 py-1 placeholder-gray-600 form-input block"
                            placeholder="email"
                            v-model="EDIT_USER.login"
                          />
                          <input
                            type="password"
                            class="rounded-sm border border-blue-100 leading-tight mx-1 my-1 px-2 py-1 placeholder-gray-600 form-input block"
                            placeholder="password"
                            v-model="EDIT_USER.password"
                          />
                        </div>
                        <div class="flex flex-row justify-start px-2 mt-2">
                          <div class="relative flex items-center my-1 leading-tight">
                            <div class="flex items-center">
                              <input
                                id="eis_admin"
                                type="checkbox"
                                class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                v-model="EDIT_USER.is_admin"
                              />
                            </div>
                            <div class="ml-1 mr-2 text-sm leading-5">
                              <label
                                for="eis_admin"
                                class="font-normal text-gray-50"
                              >admin</label>
                            </div>
                          </div>
                          <div class="relative flex items-center my-1 leading-tight">
                            <div class="flex items-center">
                              <input
                                id="eis_tech"
                                type="checkbox"
                                class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                v-model="EDIT_USER.is_tech"
                              />
                            </div>
                            <div class="ml-1 mr-2 text-sm leading-5">
                              <label
                                for="eis_tech"
                                class="font-normal text-gray-50"
                              >tech</label>
                            </div>
                          </div>
                          <div class="relative flex items-center my-1 leading-tight">
                            <div class="flex items-center">
                              <input
                                id="eis_sale"
                                type="checkbox"
                                class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                v-model="EDIT_USER.is_sale"
                              />
                            </div>
                            <div class="ml-1 mr-2 text-sm leading-5">
                              <label
                                for="eis_sale"
                                class="font-normal text-gray-50"
                              >sale</label>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row mb-1 mt-4 justify-end">
                          <div class="flex">
                            <button
                              v-on:click.prevent="UPDATE_USER(EDIT_USER)"
                              class="flex my-auto bg-blue-300 px-2 ml-1 py-1 font-normal rounded-sm shadow-sm mr-1 hover:bg-blue-200 hover:shadow-lg hover:border hover:border-gray-400"
                            >
                              Update
                            </button>
                            <button
                              v-on:click.prevent="
                                show_edit_user = !show_edit_user;
                                EDIT_USER = {};
                              "
                              class="flex my-auto bg-blue-300 px-2 ml-1 py-1 font-normal rounded-sm shadow-sm mr-1 hover:bg-blue-200 hover:shadow-lg hover:border hover:border-gray-400"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /End replace -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import User from "./components/User.vue";
export default {
  name: "Users",
  components: { User },
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {
    this.UpdateUsers();
  },
  data() {
    return {
      hasError: false,
      enableAutoUpdate: false,
      USER_OBJECT: {},
      AUTH_HEADER: "",
      NEW_USER: {
        name: "",
        login: "",
        password: "",
        is_admin: false,
        is_tech: false,
        is_sale: false,
      },
      USERS: {},
      show_edit_user: false,
      EDIT_USER: {},
      userUpdater: null,
    };
  },
  methods: {
    switchAutoUpdate: function () {
      if (this.enableAutoUpdate) {
        clearInterval(this.userUpdater);
        this.enableAutoUpdate = false;
      } else {
        this.UpdateUsers();
        this.enableAutoUpdate = true;
      }
    },
    UpdateUsers: function () {
      let that = this;
      this.GET_USERS();
      this.userUpdater = setInterval(function () {
        that.GET_USERS();
      }, 5 * 60 * 1000);
    },
    INIT_EDIT_USER(user) {
      this.EDIT_USER = user;
      this.show_edit_user = true;
    },
    UPDATE_USERS(users) {
      this.USERS = users;
    },
    GET_USERS: function () {
      this.$store.dispatch("GET_USERS", {}).then(
        (response) => {
          this.USERS = response.data;
        },
        (error) => {
          if (error.response.status >= 400) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            window.location.reload();
          }
        }
      );
    },
    ADD_USER: function () {
      if (
        this.NEW_USER.login === "" ||
        this.NEW_USER.email === "" ||
        this.NEW_USER.password === ""
      ) {
        alert("Error, incomplete user data");
        return false;
      }
      const {
        login,
        name,
        password,
        is_admin,
        is_tech,
        is_sale,
      } = this.NEW_USER;
      this.$store
        .dispatch("POST_ADD_USER", {
          login,
          name,
          password,
          is_admin,
          is_tech,
          is_sale,
        })
        .then(
          (response) => {
            this.USERS = response.data;
            this.NEW_USER = {
              name: "",
              login: "",
              password: "",
              is_admin: false,
              is_tech: false,
              is_sale: false,
            };
          },
          (error) => {
            if (error.response.status >= 400) {
              this.hasError = true;
              localStorage.removeItem("USER_OBJECT");
              localStorage.removeItem("AUTH_HEADER");
              window.location.reload();
            }
          }
        );
    },
    UPDATE_USER: function () {
      if (this.EDIT_USER.login === "" || this.EDIT_USER.email === "") {
        alert("Error, incomplete user data");
        return false;
      }
      const {
        id,
        login,
        name,
        password,
        is_admin,
        is_tech,
        is_sale,
      } = this.EDIT_USER;
      this.$store
        .dispatch("POST_UPDATE_USER", {
          id,
          login,
          name,
          password,
          is_admin,
          is_tech,
          is_sale,
        })
        .then(
          (response) => {
            this.USERS = response.data;
            this.show_edit_user = false;
            this.EDIT_USER = {};
          },
          (error) => {
            if (error.response.status >= 400 && error.response.status <= 499) {
              this.hasError = true;
              localStorage.removeItem("USER_OBJECT");
              localStorage.removeItem("AUTH_HEADER");
              this.$store.commit("UPDATE_USER_OBJECT", null);
              this.$store.commit("UPDATE_AUTH_HEADER", null);
              this.$router.push("/");
            }
            if (error.response.status >= 500 && error.response.status <= 599) {
              alert("Internal Server Error :(");
            }
          }
        );
    },
  },
};
</script>
